<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="input-group hero-search mb-3 py-2">
          <input type="text" v-model="search" class="form-control search-bar__input ui-autocomplete-input" placeholder="Search for a trail or location" id="home-search">
          <span class="input-group-append d-flex align-items-center">
              <span class="input-group-text"><span class="material-icons">search</span></span>
            </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>

    <div v-if="searchResults.length > 0">
      <div class="row">
        <p class="mb-4">
          <small><strong>Popular Trails and regions</strong></small>
        </p>
      </div>
      <div v-for="(result, index) in searchResults" :key="result.document.id">
        <a :href="result.document.url">
          <div class="row big-list">
            <div class="col-1 d-none d-md-block p-0 position-relative">
              <span class="rubik-mono"><strong>{{index+1}}</strong></span>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center pr-1">
              <img v-if="result.document.type === 'Trail'" class="img-fluid rounded w-100 lozad" :src="result.document.thumbnail_url" alt="" width="239" height="120">
              <img v-if="result.document.type === 'Poi'" class="img-fluid rounded w-100 lozad" src="../../src/img/result_poi.webp" alt="" width="239" height="120">
              <img v-if="result.document.type === 'Region'" class="img-fluid rounded w-100 lozad" src="../../src/img/result_region.webp" alt="" width="239" height="120">
              <img v-if="result.document.type === 'Country'" class="img-fluid rounded w-100 lozad" src="../../src/img/result_country.webp" alt="" width="239" height="120">
              <img v-if="result.document.type === 'ParkProvider'" class="img-fluid rounded w-100 lozad" src="../../src/img/result_park.webp" alt="" width="239" height="120">
              <img v-if="result.document.type === 'Park'" class="img-fluid rounded w-100 lozad" src="../../src/img/result_park.webp" alt="" width="239" height="120">
            </div>
            <div class="col-9 my-2 my-lg-4 d-flex align-items-center">
              <div>
                <p class="display-5 m-0 mb-1"><strong>{{ result.document.name }}</strong></p>
                <p class="m-0 mb-1 text-muted"><i>{{ result.document.type }}</i></p>
                <p class="mb-0">
                  <span class="d-block d-md-inline text-muted" rel="noopener">
                    {{ result.document.short_address }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </a>
        <hr class="my-2">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="text-center">Map of trails near <a href="/explore" class="text-yellow"><u>{{ city || "you" }}</u></a></p>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import { TypesenseSearchable } from "../utils/models/TypesenseSearchable";

export default {
  data() {
    return {
      city: '',
      region: '',
      search: '',
      searchResults: [],
    };
  },
  watch: {
    search(newVal, oldVal) {
      this.onStringChange(newVal, oldVal);
    },
  },
  methods: {
    onStringChange(newVal, oldVal) {
      TypesenseSearchable.get(newVal).then((response) => {
        this.searchResults = response.hits;
      });
    },
    getLocationDataFromWindow() {
      return {
        city: window.ip_response.city.names.en || '',
        region: window.ip_response.subdivisions[0].names.en || '',
      };
    },
    fetchInitialResults() {
      TypesenseSearchable.get(this.city).then((response) => {
        if (response.hits.length > 0) {
          this.searchResults = response.hits.slice(0, 3);
        } else {
          TypesenseSearchable.get(this.region).then((response) => {
            if (response.hits.length > 0) {
              this.searchResults = response.hits.slice(0, 3);
            } else {
              TypesenseSearchable.get(this.country).then((response) => {
                if (response.hits.length > 0) {
                  this.searchResults = response.hits.slice(0, 3);
                } else {
                  TypesenseSearchable.get('').then((response) => {
                    this.searchResults = response.hits.slice(0, 3);
                  });
                }
              });
            }
          });
        }
      });
    },
  },
  created() {
    const locationData = this.getLocationDataFromWindow();
    this.city = locationData.city;
    this.region = locationData.region;
    this.fetchInitialResults();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
